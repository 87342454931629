import React, { useContext } from "react";

import clsx from "clsx";
import { Configure, InstantSearch } from "react-instantsearch";

import { recipeMaxWidth } from "~styles/common/recipeMaxWidth.css";

import { useStoryblokRelations } from "~hooks/useStoryblokRelations";

import { transformStoryblokEntryApiToCard } from "~utils/transformers/transformStoryblokEntryApiToCard";
import { transformStoryblokEntryNodeToCard } from "~utils/transformers/transformStoryblokEntryNodeToCard";

import { Box } from "~components/Box";

import { searchClient } from "~sections/AlgoliaSearch/algoliaSearchClient";
import { ContentSearchControls } from "~sections/ContentSearchControls";

import { ALGOLIA_STORYBLOK_SEARCH_INDEX } from "~config/ALGOLIA.config";
import { PageQueryContext } from "~context/PageQueryContext";

import { ContentSearchContextProvider } from "../../context/ContentSearchContext/index";
import { ContentSearchResults } from "../ContentSearchResults/index";
import { GridCards } from "../GridCards";
import * as styles from "./styles.css";

import type { GridCardsProps } from "../GridCards";
import type { CardProps } from "~components/Card";
import type {
  SbRelationsGraphQlNode,
  StoryblokEntry,
  StoryblokRelationsNodeType,
} from "~types/storyblok.types";

export interface RelationsCardsProps
  extends Omit<GridCardsProps, "content" | "cards"> {
  content?: Array<string | StoryblokEntry>;
  isAlphabetized?: boolean;
  storyblokRelationsNodeType?: StoryblokRelationsNodeType;
  showAllContents?: boolean;
  tagsItems?: Array<SbRelationsGraphQlNode>;
  filterLabel?: string;
  dropdownPlaceholder?: string;
}

export function RelationsCards({
  content,
  subComponents,
  isAlphabetized,
  storyblokRelationsNodeType = "relationsCardsNodes",
  appearance = "blogPost",
  showAllContents,
  filterLabel,
  dropdownPlaceholder,
  ...rest
}: RelationsCardsProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { storyblokEntryParentSlug = "", topics } = pageContext || {};
  const [lang, contentType] = storyblokEntryParentSlug.split("/");

  /** Transform relations to cards */
  const cards: Array<CardProps> | null = useStoryblokRelations<CardProps>({
    content,
    transformApiEntry: transformStoryblokEntryApiToCard,
    transformGraphQlNode: transformStoryblokEntryNodeToCard,
    isAlphabetized,
    storyblokRelationsNodeType,
  });
  if (!cards) return null;

  const maxWidth = "gridSpan12";
  const widthClass: GridCardsProps["className"] =
    appearance === "report" ? clsx(recipeMaxWidth({ maxWidth })) : "";
  const isFeatured = appearance === "featured";

  const gridCardsProps = {
    subComponents,
    key: rest._uid,
    appearance,
    className: widthClass,
    ...rest,
  };

  if (isFeatured) {
    return (
      <Box
        id="featured-container"
        className={`${styles.featuredContainer} ${widthClass}`}
      >
        <GridCards {...gridCardsProps} cards={cards} />
      </Box>
    );
  }

  if (showAllContents) {
    return (
      <InstantSearch
        searchClient={searchClient}
        indexName={ALGOLIA_STORYBLOK_SEARCH_INDEX}
      >
        <Configure
          hitsPerPage={999}
          filters={`contentType:${contentType} AND lang:${lang} AND isSearchable:true`}
        />

        <ContentSearchContextProvider>
          <ContentSearchControls
            topics={topics}
            filterLabel={filterLabel}
            dropdownPlaceholder={dropdownPlaceholder}
          />
          <ContentSearchResults gridCardsProps={gridCardsProps} />
        </ContentSearchContextProvider>
      </InstantSearch>
    );
  }

  return (
    <GridCards id="grid-cards-container" {...gridCardsProps} cards={cards} />
  );
}
